<template>
  <nav id="header">
    <a class="list" @click="toggleWidth()">
      <span></span>
      <span></span>
      <span></span>
    </a>
    <a class="jpy" @click="toggleJpy()" ref="jpy"></a>
    <div class="info">
      <p>
      <label>日幣匯率</label>
      <span>{{ currency }}</span>
      </p>
      <p>
      <label>韓幣匯率</label>
      <span>{{ krw }}</span>
      </p>
      <p>
      <label>人民幣匯率</label>
      <span>{{ cny }}</span>
      </p>
    </div>
    <a @click="toggleJpy()"></a>
    <a class="account" @click="toggleInfo()" ref="account"></a>
    <div class="info">
      <p>
      <span>{{ user.name }} 您好</span>
      </p>
      <p>
      <a @click="chgPassword()">修改個人資料</a>
      <a @click="logout()">登出</a>
      </p>
    </div>
    <a @click="toggleInfo()"></a>
  </nav>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';

export default {
  name: 'header-nav',
  props: ['user'],
  data() {
    return {
      currency: '0',
      krw: '0',
      cny: '0'
    }
  },
  mounted() {
    this.getJpy();
    this.getKrw();
    this.getCny();
  },
  methods: {
    toggleJpy() {
      this.$refs.jpy.classList.toggle('show');
      (this.$refs.account.classList.contains('show')) && (this.$refs.account.classList.remove('show'));
    },
    toggleInfo() {
      this.$refs.account.classList.toggle('show');
      (this.$refs.jpy.classList.contains('show')) && (this.$refs.jpy.classList.remove('show'));
    },
    chgPassword() {
      this.toggleInfo();
      this.$router.push('/password');
    },
    logout() {
      localStorage.removeItem('remember_token');
      this.$router.push('/login');
    },
    toggleWidth() {
      let page = document.getElementById('page');
      page.classList.toggle('more');
    },
    getJpy() {
      let config = {
        method: 'get',
        url: `/api/jpy?title=JPY`,
        data: ''
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        if (data.err) {
          alert(data.err);
        } else {
          if (data.list.length) {
            this.currency = data.list[0].content;
          }
        }
      }).catch((e) => {
        console.log(e);
      });
    },
    getKrw() {
      let config = {
        method: 'get',
        url: `/api/jpy?title=KRW`,
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        if (data.err) {
          alert(data.err);
        } else {
          if (data.list.length) {
            this.krw = data.list[0].content;
          }
        }
      }).catch((e) => {
        console.log(e);
      });
    },
    getCny() {
      let config = {
        method: 'get',
        url: `/api/jpy?title=CNY`,
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        if (data.err) {
          alert(data.err);
        } else {
          if (data.list.length) {
            this.cny = data.list[0].content;
          }
        }
      }).catch((e) => {
        console.log(e);
      });
    }
  }
}
</script>

<style lang="scss" scope>
nav#header {
  width: calc(100% - 360px);
  height: 74px;
  background-color: #fff;
  background-color: hsla(0,0%,100%,.7);;
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 12px;
  transition: width ease-in 0.3s;
  box-shadow: 0 6px 24px rgba(10,10,102,.09803921568627451);
  backdrop-filter: blur(10px);
  > a.list {
    position: absolute;
    width: 44px;
    height: 44px;
    background-color: #e9be8b;
    border-radius: 50%;
    top: 15px;
    left: 20px;
    display: block;
    padding: 11px 14px 6px 14px;
    margin: 0 9px 6px 0px;
    box-sizing: border-box;
    > span {
      margin: 4px 0 0 0;
      background-color: #fff;
      display: block;
      border-radius: 1px;
      height: 2px;
    }
  }
  > span.currency {
    position: absolute;
    width: 68px;
    height: 41px;
    line-height: 41px;
    padding: 0 0 0 21px;
    font-size: 8pt;
    top: 15px;
    left: 76px;
    display: block;
    background: rgba(0, 0, 0, 0.4) url("@/assets/img/jpy.png") no-repeat left center;
    color: #fff;
    font-weight: bold;
    background-size: 20.5px 14px;
    box-sizing: border-box; 
  }
  > a.account {
    position: absolute;
    width: 44px;
    height: 44px;
    top: 15px;
    right: 20px;
    display: block;
    background: transparent url("@/assets/img/account.png") no-repeat center center;
    background-size: 32px 32px;
    cursor: pointer;
    & + div {
      display: none;
      position: fixed;
      top: 69px;
      right: 20px;
      width: 290px;
      border-radius: 12px;
      background-color: #fff;
      box-shadow: 0 6px 24px rgba(10, 10, 10, 0.7);
      z-index: 1113;
      &::before {
        content: '';
      }
      &::after {
        content: '';
      }
      > p:first-child {
        width: 100%;
        display: block;
        padding: 24px;
        border-bottom: 2px solid #f5f6f7;
        > span {
          display: block;
          width: 250px;
          height: 44px;
          line-height: 44px;
          font-size: 24px;
          font-weight: bold;
          float: left;
          padding: 0 16px;
        }
      }
      > p:first-child::before {
        content: '';
        display: none;
        width: 76px;
        height: 76px;
        border: 6px solid #1919ff;
        border-radius: 50%;
        float: left;
        background: transparent url("@/assets/img/account2.png") no-repeat center center;
        background-size: 68px 68px;

      }
      > p:first-child::after {
        content: '';
        display:block;
        clear: both;
        width: 100%;
        height: 0;
        overflow: hidden;
      }
      > p:last-child {
        text-align: center;
        padding: 0 24px 0 24px;
        display: flex;
      }
      > p > a {
        display: block;
        width: 50%;
        flex: 1;
        text-decoration: none;
        cursor: pointer;
        color: #111;
      }
      & + a {
        display: none;
        z-index: 1112;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0;
      }
    }
    &.show + div {
      display: block;
      & + a {
        display: block;
      }
    }
  }
  > a.jpy {
    position: absolute;
    width: 72px;
    height: 44px;
    top: 15px;
    right: 64px;
    display: block;
    background: transparent url("@/assets/img/jpy-icon.png") no-repeat center center;
    background-size: 50px 50px;
    border-radius: 22px;
    cursor: pointer;
    & + div {
      display: none;
      position: fixed;
      z-index: 1113;
      top: 69px;
      right: 20px;
      width: 290px;
      border-radius: 12px;
      background-color: #fff;
      box-shadow: 0 6px 24px rgba(10, 10, 10, 0.7);
      &::before {
        content: '';
      }
      &::after {
        content: '';
      }
      > p {
        width: 100%;
        display: block;
        padding: 8px 24px;
        margin: 0 0 12px 0;
        > label {
          display: block;
          width: 250px;
          height: 20px;
          line-height: 20px;
          font-size: 16px;
          font-weight: bold;
          float: left;
          padding: 0 16px;
        }
        > span {
          display: block;
          width: 250px;
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          float: left;
          padding: 0 16px;
        }
        &::after {
          content: '';
          display:block;
          clear: both;
          width: 100%;
          height: 0;
          overflow: hidden;
        }
      }
      & + a {
        display: none;
        z-index: 1112;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0;
      }
    }
    &.show + div {
      display: block;
      & + a {
        display: block;
      }
    }
  }
  z-index: 1111;
}
@media (max-width: 500px) and (orientation: portrait) {
  nav#header {
    width: calc(100vw - 20px);
    height: 54px;
    background-color: hsla(0,0%,100%,.8);;
    position: fixed;
    top: 10px;
    right: 10px;
    border-radius: 12px;
    > a.list {
      display: none;
    }
    > span.currency {
      top: 8px;
      left: 10px;
      right: auto;
    }
    > a.account {
      top: 8px;
      right: 10px;
      & + div {
        top: 69px;
        right: 20px;
        width: 280px;
      }
    }
    > a.jpy {
      top: 8px;
      right: 54px;
      & + div {
        top: 69px;
        right: 20px;
        width: 290px;
      }
    }
  }

}
@media (max-width: 900px) and (orientation: landscape) {
  nav#header {
    width: calc(100vw - 20px);
    height: 54px;
    background-color: #fff;
    position: fixed;
    top: 10px;
    right: 10px;
    border-radius: 12px;
    > a.list {
      display: none;
    }
    > span.currency {
      top: 8px;
      left: 10px;
      right: auto;
    }
    > a.account {
      top: 8px;
      right: 10px;
      & + div {
        top: 69px;
        right: 0px;
        width: 290px;
        width: 100%;
      }
    }
    > a.jpy {
      top: 8px;
      right: 54px;
      & + div {
        top: 69px;
        right: 20px;
        width: 290px;
      }
    }
  }
}

</style>
