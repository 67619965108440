<template>
  <teleport to="body"> 
    <div class="agreen_mask" :class="{show:isShow}">
      <div class="agreen_box_outer">
        <div class="agreen_box">
          <div class="title">
            {{ title }}
            <div class="close" @click="yes()"></div>
          </div>
          <div class="content">
            <p>
            本公司會員（以下稱「甲方」），委託境外集運商（以下稱「乙方」），進口貨件後交由甲方。乙方負責代收、倉儲、報關及航線安排，同時委託連線國際物流有限公司（以下稱「丙方」）提供資訊服務、代理收付款項等支援，以及協助處理台灣境內的報關、理貨和運送事宜。完成清關後，貨件由乙方或丙方的合作夥伴（以下稱「丁方」）運送至甲方。雙方在以下條款下達成協議，甲方委託乙方處理貨件進口業務。
            </p>
            <ol>
              <li> 
                基本條款
                <div>
                  <p>甲方使用丙方平台服務時，即表示甲方認知以下事項：</p>
                  <ol>
                    <li>丙方提供之服務是由貨物抵達乙方倉庫並由乙方簽收後開始。</li>
                    <li>「貨件」是指甲方委託乙方運送的文件或貨物。</li>
                    <li>丙方網站公告的運費、附加服務費或其他因運送、出口、進口需求所額外產生之費用。</li>
                    <li>乙方、丙方及丁方有權在不事先通知甲方的情況下，根據安全、保護、海關或其他管制要求對貨件進行開箱抽驗、整併和分拆。</li>
                    <li>丙方僅提供資訊服務，協助處理台灣境內的進口報關、理貨和運送事宜，並不能保證貨物的內容、尺寸、顏色、規格、品質及數量等的正確性。</li>
                    <li>丙方提供之服務不負責一切關於商品相關瑕疵（貨物少發、漏發、商品品質問題等）及侵害知識產權等責任。</li>
                    <li>認知國際運輸存在風險，貨件在運送過程中（包括海關檢查）造成損壞時，除非加購保價服務，否則無法理賠。</li>
                    <li>乙方、丙方及丁方有權自主決定貨物的運送路線、改變路線或進行中繼站轉送等相關事宜。</li>
                    <li>配送至乙方提供倉庫的包裹，如超過 D+30 日倉儲時間（包裹到倉當日為D日）（美國倉儲倉時間為D+15日），丙方有權通知乙方銷毀該包裹。</li>
                    <li>同意遵守平台規定及相關法律規定。</li>
                  </ol>
                </div>
              </li>
              <li>
                不可運送貨件
                <div>
                  <ol>
                    <li>不得運送包括但不限於完整的槍支、彈藥、爆炸物、爆裂裝置、氣槍、偽造品、現金、貴重金屬、活體動物、禁止出口入境的物品、危險品或乙方認定無法安全或合法運送的貨件。</li>
                  </ol>
                </div>
              </li>
              <li>
                境外乙方收貨
                <div>
                  <ol>
                    <li>甲方需在丙方平台填寫集運單，由丙方通知乙方貨件抵達信息。</li>
                    <li>貨件抵達乙方集運站時，如外箱嚴重損壞、浸水或已知為管制品，乙方有權拒收並通知甲方。</li>
                  </ol>
                </div>
              </li>
              <li>
                運費
                <div>
                  <ol>
                    <li>運費根據乙方所在國家不同的服務方式收取，詳細費率請參照丙方網站公告。</li>
                    <li>若運費因出口或進口國海關查扣而無法送達，不予退費。</li>
                  </ol>
                </div>
              </li>
              <li>
                出口、進口報關
                <div>
                  <ol>
                    <li>甲方提交發貨後，乙方負責包裝出口並根據貨件性質選擇適當的打包方式。</li>
                    <li>貨件在運送過程中，如因乙方決定的包裝方式導致重量增加或減少，運費不予退補。</li>
                    <li>甲方提交發貨時需實名制填寫基本資料。</li>
                  </ol>
                </div>
              </li>
              <li>
                甲方承諾丙方必須遵守的事項
                <div>
                  <ol>
                    <li>遵守台灣海關及相關貨品進出口主管機關的規定。</li>
                    <li>如甲方違反相關法律，產生的民刑事責任及行政責任由甲方自行負責，與丙方無關。</li>
                  </ol>
                </div>
              </li>
              <li>
                理賠事項
                <div>
                  <ol>
                    <li>在貨件運送過程中發生損失或滅失時，丙方將代甲方向乙方申請賠償，賠償金額以甲方購買價格為基準，每件貨物的最高理賠上限為NTD3000元。</li>
                    <li>如貨件遺失，甲方需向運送業者申請蓋有乙方收貨證明的書面簽收單，並依流程申請理賠。</li>
                  </ol>
                </div>
              </li>
              <li>
                不理賠事項
                <div>
                  <ol>
                    <li>在未購買保價服務的情況下，貨件運送過程中造成的損壞無法理賠。</li>
                    <li>
                      天然災害所造成的破損或貨件遺失，也不在丙方承擔的範圍內，將不獲得任何賠償:
                      <p>-運輸途中由於氣候惡劣、雷電、地震、洪水等自然災害所造成的遺失或毀損。</p>
                      <p>-任何由於溫度、濕度變化而導致的毀損</p>
                      <p>-大自然所造成，如地震、龍捲風、颶風、洪水、水災、大霧、大雪、霜凍天氣或地質災害等不可控因素。</p>
                    </li>
                    <li>如甲方無法提供證據證明在產品抵台前已損壞的情況下，丙方無法代位向丁方申請理賠。</li>
                    <li>
                      以下情況所造成的破損不在丙方提供之服務承擔的範圍內，將不獲得任何賠償:
                      <p>-含液體、危險品及禁運物品</p>
                      <p>-易碎、沒有合適保護及容易破損的物品(如: 陶瓷製品、玻璃製品、塑膠製品、書籍含紙類製品、電子產品、CD&DVD、化妝品類(如眼影盤、口紅)等)</p>
                      <p>-貨品本身包裝不當，沒有提供適當保護</p>
                      <p>-原封包裝完好的貨品</p>
                      <p>-包裝變形、折損等，但裡面商品是完好的</p>
                      <p>-非紙箱或木箱包裝、非密封包裝貨件</p>
                      <p>其他無法預期及不可抗力之因素所造成的破損或貨件遺失，不在本網提供之服務承擔的範圍內，將不獲得任何賠償:</p>
                      <p>-任何海關、運輸公司、機場及政府當局所導致的情況，如海關退運、航班延誤、底片曝光、音帶消除等</p>
                      <p>-沒有申報、虛報、刻意隱瞞或無法提供包裹追蹤碼的貨件</p>
                      <p>-沒有任何簽收或快遞證明已派送至海外倉庫的貨件</p>
                      <p>-海外收件地址填寫錯誤或未準確按照會員中心內的倉庫地址及收件人格式填寫的貨件</p>
                      <p>-因清關時效而導致的過保質期、過保修期等</p>
                      <p>-任何地區性糾紛，如入侵、暴動、罷工、騷亂、內亂、戰爭等</p>
                      <p>-任何國家實施的臨時禁運措施</p>
                      <p>-任何災害（如：火災、水災等）或是運輸途中因意外、失事、車禍等</p>
                      <p>-因天氣因素（如：雨）造成包裹淋濕、破損、損壞。</p>
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                收據及發票
                <div>
                  <ol>
                    <li>丙方係屬系統服務提供商，乙方對於會員所授權之任何案件僅收取系統服務費，其系統服務費請參考費用說明。關於該費用之發票，丙方將於會員所授權之案件結案後，始開立。會員所透過乙方提供之平台代為集運國外商品，集運部份由委託之物流業者進行配送，對於商品運費部份，丙方並無賺取任何差價獲利。丙方於收到會員運費款項後，依照約定週期將該運費新台幣金額全數匯給委託之物流業者，此部份均屬代收代付行為。</li>
                    <li>甲方需支付丙方的其他服務費用（如金流服務費），將依法開立電子發票。</li>
                  </ol>
                  <p>以上為雙方協議之條款，甲方委託乙方處理貨件進口業務，並遵守相關規定及程序。丙方保有變更本服務條款之內容與解釋本集運條款的權利。</p>
                </div>
              </li>
            </ol>
            <ol v-show="false">
              <li> 本網提供之服務不負責一切關於商品相關瑕疵（貨物少發、漏發、商品品質問題等）及侵害知識產權等責任。</li>
              <li> 本網提供之服務是由貨物抵達我們的倉庫後開始。</li>
              <li>
                以下情況所造成的破損不在本網提供之服務承擔的範圍內，將不獲得任何賠償:
                <div> -含液體、危險品及禁運物品</div>
                <div> -易碎、沒有合適保護及容易破損的物品(如: 陶瓷製品、玻璃製品、塑膠製品、書籍含紙類製品、電子產品、CD&amp;DVD、化妝品類(如眼影盤、口紅)等)</div>
                <div> -貨品本身包裝不當，沒有提供適當保護</div>
                <div> -原封包裝完好的貨品</div>
                <div> -包裝變形、折損等，但裡面商品是完好的</div>
                <div> -非紙箱或木箱包裝、非密封包裝貨件</div>
              </li>
              <li>
                天然災害所造成的破損或貨件遺失，也不在本網承擔的範圍內，將不獲得任何賠償:
                <div> -運輸途中由於氣候惡劣、雷電、地震、洪水等自然災害所造成的遺失或毀損。</div>
                <div> -任何由於溫度、濕度變化而導致的毀損</div>
                <div> -大自然所造成，如地震、龍捲風、颶風、洪水、水災、大霧、大雪、霜凍天氣或地質災害等不可控因素。</div>
              </li>
              <li>
                其他無法預期及不可抗力之因素所造成的破損或貨件遺失，不在本網提供之服務承擔的範圍內，將不獲得任何賠償:
                <div> -任何海關、運輸公司、機場及政府當局所導致的情況，如海關退運、航班延誤、底片曝光、音帶消除等</div>
                <div> -沒有申報、虛報、刻意隱瞞或無法提供包裹追蹤碼的貨件</div>
                <div> -沒有任何簽收或快遞證明已派送至海外倉庫的貨件</div>
                <div> -海外收件地址填寫錯誤或未準確按照會員中心內的倉庫地址及收件人格式填寫的貨件</div>
                <div> -因清關時效而導致的過保質期、過保修期等</div>
                <div> -任何地區性糾紛，如入侵、暴動、罷工、騷亂、內亂、戰爭等</div>
                <div> -任何國家實施的臨時禁運措施</div>
              </li>
              <li> 航空易燃易爆物品、植物種子、活體動物、純鋰電池（內含電池可）、藥物、及台灣海關限制進口等商品無法運送。</li>
              <li> 認知國際運輸具有一定風險，貨件於運送途中造成損壞（包括海關查驗）無法理賠。</li>
              <li> 若未添加任何運輸保險，航段遺失最高賠償上限為每筆一千元新臺幣整。</li>
              <!--<li> 若於倉庫遺失確認為倉庫責任會代協商做全額賠償。</li>-->
              <li> 任何違反運送條款或遭海關限制出口/進口之退回商家費用自行負責。</li>
              <li> 本網保有變更本服務條款之內容與解釋本集運條款的權利。</li>
            </ol>
            <div style="box-sizing: border-box; caret-color: rgb(87, 82, 80); color: rgb(87, 82, 80); font-family: Montserrat, sans-serif; font-size: 16px; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none;">
              <div dir="auto" style="box-sizing: border-box;"> （最後更新日期 2024/10/10）</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport> 
</template>

<script>
export default {
  name: 'agreen-rule',
  emits: ['toInput'],
  props: {
    isShow: {default: false},
    title: {default: '服務暨集運條款'},
  },
  mounted() {
  },
  methods: {
    yes() {
      this.$emit('toInput', '0');
    },
  }
}
</script>

<style scope lang="scss">
.agreen_mask { 
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw; 
    height: 100vh;
    z-index: -1;
    visibility: hidden;
    backdrop-filter: blur(10px);
}
.agreen_mask.show { 
    z-index: 10102;
    visibility: visible;
}
.agreen_box_outer {
    position: fixed;
    z-index: 10103;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    text-align: center;
    padding: 0;
    box-sizing: border-box;
}
.agreen_box_outer::before {
    content : '';
    display: block;
    width: 100%;
    height: 20%;
    margin-bottom: -70px;
}
.agreen_mask > .agreen_box_outer > .agreen_box { 
    transition: all 0.2s ease-in;
    transform: scale(0.2, 0.2);
    opacity: 0;
}
.agreen_mask.show > .agreen_box_outer > .agreen_box { 
    transform: scale(1, 1);
    opacity: 1;
}
.agreen_box {
    background-color: #f8f8f8;
    line-height: 0;
    border-radius: 6px;
    font-size: 10pt;
    margin: 0 auto;
    width: 80%;
    min-width: 320px;
    overflow: hidden;
    border-radius: 30px;
    box-shadow: 0 0 5px rgba(0,0,0,0.7);
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.agreen_box .title {
    width: auto; 
    margin: 1px;
    display: block;
    height: 45px;
    line-height: 45px;
    background-color: #f8f8f8;
    color: #000;
    font-weight: bold;
    position: relative;
    box-sizing: border-box;
    caret-color: rgb(87, 82, 80);
    color: rgb(87, 82, 80);
    font-family: Montserrat, sans-serif;
    font-size: 14pt;
    font-style: normal;
    font-variant-caps: normal;
    font-weight: bold;
    letter-spacing: normal;
    orphans: auto;
    text-align: center;
    text-indent: 0px;
    text-transform: none;
    white-space: normal;
    widows: auto;
    word-spacing: 0px;
    -webkit-text-size-adjust: auto;
    -webkit-text-stroke-width: 0px;
    text-decoration: none;

}
.agreen_box .title .close{
    position: absolute;
    width: 24px;
    height: 24px;
    line-height: 10px;
    top: 16px;
    right: 20px;
    color: #000;
    text-shadow: none;
    cursor: pointer;
    background: transparent url("@/assets/img/close.png") no-repeat center center;
    background-size: 24px 24px;
}
.agreen_inner {
    margin: 0;
    padding: 0.5em; 
    background: #f8f8f8;
    line-height: 10pt;
    font-size: 10pt;
    text-align: center;
    min-height: 50px;
    overflow-x: inherit;
    overflow-y: auto;
    box-sizing: border-box;
}
.agreen_box > div.content {
  height: 60vh;
  overflow: auto;
  padding: 0 20px 0 20px;
  > ol {
    list-style-type: cjk-decimal;
    > li {
      line-height: 24px;
      caret-color: rgb(87, 82, 80);
      color: rgb(87, 82, 80);
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-variant-caps: normal;
      font-weight: 400;
      font-weight: bold;
      letter-spacing: normal;
      orphans: auto;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: auto;
      word-spacing: 0px;
      -webkit-text-size-adjust: auto;
      -webkit-text-stroke-width: 0px;
      text-decoration: none;
      > div {
        font-weight: normal;
        > ol {
          padding-left: 24px;
          > li > p {
            margin: 0;
          }
        }
      }
    }
  }
  div {
    line-height: 24px;
    caret-color: rgb(87, 82, 80);
    color: rgb(87, 82, 80);
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: auto;
    text-align: start;
    text-indent: 0px;
    text-transform: none;
    white-space: normal;
    widows: auto;
    word-spacing: 0px;
    -webkit-text-size-adjust: auto;
    -webkit-text-stroke-width: 0px;
    text-decoration: none;
    > div {
      text-align: left;
    }
  }
  > p {
    line-height: 24px;
    font-size: 16px;
    text-align: left;
    font-weight: normal;
  }
}
.agreen_box > label {
    color: #6f6f80;
    display: block;
    height: 24px;
    line-height: 24px;
    width:80%;
    text-align: left;
    margin: 0 auto;
}
.agreen_box > input[type=date] {
    width: 80%;
    font-size: 11pt;
    margin: 12px auto;
    border-radius: 0px;
    border: 1px solid #bbbbbb;
}
.agreen_box > input[type=number], 
.agreen_box > input[type=text] {
    height: 55px;
    line-height: 55px;
    width: 80%;
    display: block;
    border-radius: 8px;
    border: 1px solid #6f6f80;
    padding: 0 16px;
    font-size: 18px;
    margin: 0 auto 20px auto;
}
.agreen_box > input[type=number]::placeholder, 
.agreen_box > input[type=text]::placeholder {
  color: red;
}
.agreen_box > input[type=text] + b {
    display: none;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 15px 0 0 -28px;
    background-size: 24px 24px;
}
.agreen_box > input[type=text]::ms-clear {
    display: none;
}
.agreen_box > input[type=text]:valid + b {
    display: inline;
}

.agreen_box > a {
    display: block;
    float: left;
    text-align: center;
    width: 100%;
    height: 44px;
    line-height: 44px;
    font-family: inherit;
    font-size: 12pt;
    text-overflow: ellipsis;
    text-decoration: none;
    white-space: nowrap;
    color: rgb(0,122,255);
    border-top: 1px solid rgb(218,218,218);
    background-color: #f8f8f8;
    box-sizing: border-box;
}
.agreen_box::after {
    content: '';
    height: 0;
    width: 100%;
    overflow: hidden;
    clear: both;
}
</style>
