<template>
  <div class="outer-box">
    <div class="picture">
    </div>
    <div class="inner-box">
      <div class="login-box">
        <template v-if="show_email == ''">
          <div class="logo-box">
            <h3>星球集運</h3>
          </div>
          <input type="radio" name="act" id="login_radio" checked="true" />
          <input type="radio" name="act" id="reg_radio" />
          <input type="radio" name="act" id="forget_radio" />
          <nav>
            <div class="tab" v-if="forget == false">
              <label for="login_radio">登入</label>
              <label for="reg_radio">註冊</label>
            </div>
            <template v-else>
              <a class="back" @click="backLogin()">返回</a>
              <div class="tab">
                <label>忘記密碼</label>
              </div>
            </template>
          </nav>
          <div class="login">
            <form name="login_form" method="post" action="/api/login" @submit.prevent="toLogin()" ref="login_form" >
              <input type="hidden" name="key" ref="forget_key" />
              <div>
                <a v-show="google_url" :href="google_url" class="google-login"></a>
              </div>
              <div>
                <label>電子郵件</label>
                <div>
                  <input type="email" name="email"  placeholder="email@examp.com"/>
                </div>
              </div>
              <div>
                <label class="password">密碼</label>
                <a class="forget" @click="goForget()">忘記密碼?</a>
                <div>
                  <input type="password" name="password" />
                </div>
              </div>
              <div>
                <label>驗證碼</label>
                <div v-if="!recaptcha">
                  <input class="code" type="text" name="login_code" />
                  <img ref="img" alt="驗證碼" />
                  <b @click="getCode()"></b>
                </div>
                <div v-show="recaptcha">
                  <div class="g-recaptcha" ref="recaptcha_1"></div>
                </div>
              </div>
              <div>
                <button>登入</button>
              </div>
            </form>
          </div>
          <div class="forget">
            <form name="forget_form" method="post" action="/api/forget" @submit.prevent="toForget()" ref="forget_form" >
              <input type="hidden" name="key" ref="key" />
              <div>
                <label>電子郵件</label>
                <div>
                  <input type="email" name="email"  placeholder="email@examp.com"/>
                </div>
              </div>
              <div>
                <label>驗證碼</label>
                <div v-if="!recaptcha">
                  <input class="code" type="text" name="forget_code" />
                  <img ref="forget_img" alt="驗證碼" />
                  <b @click="getCode()"></b>
                </div>
                <div v-show="recaptcha">
                  <div class="g-recaptcha" ref="recaptcha_2"></div>
                </div>
              </div>
              <div>
                <button>送出 </button>
              </div>
            </form>
          </div>
          <div class="reg">
            <form name="reg_form" method="post" action="/api/reg" @submit.prevent="toReg()" ref="reg_form" >
              <input type="hidden" name="key" ref="reg_key" />
              <div>
                <label>電子郵件</label>
                <div>
                  <input type="email" required name="email" />
                </div>
              </div>
              <div>
                <label>密碼</label>
                <div>
                  <input type="password" name="password" />
                </div>
              </div>
              <div>
                <label>確認密碼</label>
                <div>
                  <input type="password" name="re_password" />
                </div>
              </div>
              <template v-if="step > 1">
                <div v-show="false">
                  <label>姓名</label>
                  <div>
                    <input type="text" name="name" />
                  </div>
                </div>
                <div>
                  <label>姓</label>
                  <div>
                    <input type="text" required name="last_name" />
                  </div>
                </div>
                <div>
                  <label>名</label>
                  <div>
                    <input type="text" required name="first_name" />
                  </div>
                  <label>聯絡電話</label>
                  <div>
                    <input type="text" name="tel" placeholder="選填" />
                  </div>
                </div>
              </template>
              <template v-if="step == 3">
                <div>
                  <label>性別</label>
                  <div>
                    <span><input type="radio" name="gender" checked />不公開</span>
                    <span><input type="radio" name="gender" value="M" />男</span>
                    <span><input type="radio" name="gender" value="F" />女</span>
                  </div>
                </div>
                <div>
                  <label>生日</label>
                  <div>
                    <input type="date" name="birthday" required value="" />
                  </div>
                </div>
                <div>
                  <label>從哪裡認識我們</label>
                  <div>
                    <p><input type="checkbox" name="know_us_from[]" value="Facebook" />FaceBook</p>
                    <p><input type="checkbox" name="know_us_from[]" value="Instagram" />Instagram</p>
                    <p><input type="checkbox" name="know_us_from[]" value="Google" />Google搜尋</p>
                    <p><input type="checkbox" name="know_us_from[]" value="友人介紹" />友人介紹</p>
                    <p><input type="checkbox" name="know_us_from[]" value="Dcard" />Dcard</p>
                    <p><input type="checkbox" name="know_us_from[]" value="部落格推薦" />部落格推薦</p>
                    <p>
                      <input type="checkbox" ref="etc" name="know_us_from[]" value="" />其他
                      <input type="text" @input="chgEtc($event)" />
                    </p>
                  </div>
                </div>
                <div>
                </div>
              </template>
              <div v-show="step == 3">
                <label>驗證碼</label>
                <div v-if="!recaptcha">
                  <input class="code" type="text" name="reg_code" />
                  <img ref="reg_img" alt="驗證碼" />
                  <b @click="getCode()"></b>
                </div>
                <div v-show="recaptcha">
                  <div class="g-recaptcha" ref="recaptcha_3"></div>
                </div>
              </div>
              <div v-show="step == 3">
                <p>
                <input type="checkbox" name="agreen" v-model="agreen"/>
                <b>本人同意<a :style="{'cursor': 'pointer'}" @click="showRule()">【服務暨集運條款】</a></b>
                </p>
                <button v-show="agreen">註冊</button>
              </div>
              <div class="next" v-show="step < 3">
                <a @click="regNext()">繼續</a>
              </div>
            </form>
          </div>
        </template>
        <template v-else>
          <div class="logo-box">
            <h3>星球集運</h3>
          </div>
          <div class="mail">
            <i></i>
          </div>
          <h3>檢查您的收件匣</h3>
          <p>我們已將認證信發送至您所註冊的信箱，請前往您的收件匣來啟用帳號。(根據信箱安全級別的不同，有可能被歸類到垃圾信件中) </p>
          <p>註冊信箱<b>{{show_email}}</b></p>
          <div>
            未收到驗證信？
            <a @click="reMail()">重新傳送</a>
          </div>
        </template>
      </div>
    </div>
    <agreen-rule v-bind="rule_dialog" @toInput="closeAgreen"></agreen-rule>
  </div>
</template>

<script>
import axios from 'axios';
import AgreenRule from '@/components/AgreenRule.vue';
console.log(AgreenRule);

export default {
  name: 'LoginView',
  props: ['user_rows'],
  data() {
    return {
      show_email: '',
      forget: false,
      recaptcha: false,
      google_url: null,
      agreen: false,
      rule_dialog: {
        isShow: false,
        title: '【服務暨集運條款】',
      },
      step: 1
    }
  },
  components: {
    AgreenRule
  },
  mounted() {
    this.checkUserRows();
    this.getCode();
    this.getGoogleUrl();
    if (this.$route.query.code) {
      this.getGoogleUser(this.$route.query.code);
    }
  },
  watch: {
    user_rows: function (newVal, oldVal) {
      if (Object.keys(newVal).length && Object.keys(oldVal).length == 0) {
        this.$router.push('/');
      }
    }
  },
  methods: {
    showRule() {
      this.rule_dialog.isShow = true;
    },
    closeAgreen(val) {
      if (val.length) {
        this.rule_dialog.isShow = false;
      }
    },
    chgEtc(e) {
      let inp = e.target;
      if (inp.value.length == 0) {
        this.$refs.etc.value = '';
      } else {
        this.$refs.etc.value = inp.value;
      }
    },
    getGoogleUrl() {
      let config = {
        method: 'get',
        url: '/api/oauth',
        data: ''
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        this.google_url = data.ret;
      }).catch((e) => {
        console.log(e);
      });
    },
    getGoogleUser(code) {
      let fData = new FormData();
      fData.append('code', code);
      let config = {
        method: 'post',
        url: `/api/oauth/login`,
        data: fData
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        if (data.result == 1) { 
          localStorage.setItem('remember_token', data.token);
          this.$emit('updateStatus', data.ret);
        } else if (data.err) {
          alert(data.err);
        }
      }).catch((e) => {
        console.log(e);
      });
    },
    checkUserRows() {
      if (Object.keys(this.$props.user_rows).length) {
        self.location = '/';
      }
    },
    goForget() {
      let rad = document.getElementById('forget_radio');
      if (rad) {
        rad.checked = true;
        this.forget = true;
      }
    },
    backLogin() {
      let rad = document.getElementById('login_radio');
      if (rad) {
        rad.checked = true;
        this.forget = false;
      }
    },
    getCode() {
      let config = {
        method: 'get',
        url: '/api/login',
        data: ''
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        if (data.recaptcha) {
          this.recaptcha = true;
          (this.$refs.recaptcha_1) && (this.$refs.recaptcha_1.setAttribute('data-sitekey', data.recaptcha));
          (this.$refs.recaptcha_2) && (this.$refs.recaptcha_2.setAttribute('data-sitekey', data.recaptcha));
          (this.$refs.recaptcha_3) && (this.$refs.recaptcha_3.setAttribute('data-sitekey', data.recaptcha));
          let recaptchaScript = document.createElement('script')
          recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
          document.head.appendChild(recaptchaScript)
        } else if (this.show_email == '' && this.$refs.img) {
          this.$refs.img.src = data.ret.img;
          this.$refs.key.value = data.ret.key;
          this.$refs.reg_img.src = data.ret.img;
          this.$refs.reg_key.value = data.ret.key;
          this.$refs.forget_img.src = data.ret.img;
          this.$refs.forget_key.value = data.ret.key;
        }
      }).catch((e) => {
        console.log(e);
      });
    },
    regNext() {
      //this.$refs.next.classList.toggle('show');
      this.step += 1;
    },
    toLogin() {
      let post_data = new FormData(this.$refs.login_form);
      let config = {
        method: 'post',
        url: this.$refs.login_form.getAttribute('action'),
        data: post_data 
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        if (data.result == 1) { 
          localStorage.setItem('remember_token', data.token);
          this.$emit('updateStatus', data.ret);
        } else if (data.err) {
          alert(data.err);
          (data.ret.email) && (this.show_email = data.ret.email);
        }
      }).catch((e) => {
        console.log(e);
      });
    },
    toReg() {
      let post_data = new FormData(this.$refs.reg_form);
      if (post_data.get('know_us_from[]') === null) {
        alert('至少勾選一項【從哪裡認識我們】');
        return false;
      }
      if (this.$refs.etc.value == '' && this.$refs.etc.checked) {
        alert('麻煩填寫從哪裡認識我們');
        return false;
      }
      let config = {
        method: 'post',
        url: this.$refs.reg_form.getAttribute('action'),
        data: post_data 
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        if (data.result == 1) { 
          this.show_email = data.ret.email;
        } else if (data.err) {
          alert(data.err);
        }
      }).catch((e) => {
        console.log(e);
      });

    },
    toForget() {
      let post_data = new FormData(this.$refs.forget_form);
      let config = {
        method: 'post',
        url: this.$refs.forget_form.getAttribute('action'),
        data: post_data 
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        if (data.result == 1) { 
          alert(data.ret);
        } else if (data.err) {
          alert(data.err);
        }
      }).catch((e) => {
        console.log(e);
      });

    },
    reMail() {
      let post_data = new FormData();
      post_data.append('email', this.show_email);
      let config = {
        method: 'post',
        url: '/api/remail',
        data: post_data 
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        if (data.result == 1) { 
          alert('已重新寄信');
        } else if (data.err) {
          alert(data.err);
          (data.ret.email) && (this.show_email = data.ret.email);
        }
      }).catch((e) => {
        console.log(e);
      });
    },

  }
}
</script>

